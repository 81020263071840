import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import 'firebase/compat/database';
import config from '../config';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail, updatePassword, updateEmail, initializeAuth, indexedDBLocalPersistence } from 'firebase/auth';
import { getDatabase, ref } from 'firebase/database';

/**
 * Firebase class
 */
class Firebase {
  /**
   * constructor method
   */
  constructor() {
    const app = initializeApp(config);

    if (typeof window !== 'undefined') {
      this.auth = initializeAuth(app, { persistence: indexedDBLocalPersistence });
      this.db = getDatabase();;
    }
  }

  /**
   * Create user in firebase auth
   * @param {string} email - user's email
   * @param {string} password - user's password
   * @returns {Object}
   */
  doCreateUserWithEmailAndPassword = (email, password) => (
    createUserWithEmailAndPassword(this.auth, email, password)
    //this.auth.createUserWithEmailAndPassword(email, password)
  );


  /**
   * Authenticate user
   * @param {string} email - user's email
   * @param {string} password - user's password
   * @returns {Object}
   */
  doSignInWithEmailAndPassword = (email, password) => (
    signInWithEmailAndPassword(this.auth, email, password)
    //this.auth.signInWithEmailAndPassword(email, password)
  );

  /**
   * log out user
   * @returns {null}
   */
  doSignOut = () => signOut(this.auth);

  /**
   * Resets user password
   * @param {string} email - user's email
   * @returns {null}
   */
  doPasswordReset = email => sendPasswordResetEmail(this.auth, email);

  /**
   * Updates user's password
   * @param {string} password - user's password
   * @returns {null}
   */
  doPasswordUpdate = password => (
    updatePassword(this.auth.currentUser, password)
  );

  /**
   * Updates user's password
   * @param {string} email - new user's password
   * @returns {null}
   */
  doEmailUpdate = email => (
    updateEmail(this.auth.currentUser, email)
  );

  /**
   * User APi
   * @param {string} uid - user id
   * @returns {Object}
   */
  user = uid => ref(this.db, `users/${uid}`);
}

export default Firebase;
